<template>
  <v-container fluid>
    <v-card class="mx-auto" max-width="900">
      <div class="text">
        <div class="text_inner">
          <div class="logo"></div><br>
          <strong>Fuchshausstraße 253 A<br>5721 Piesendorf</strong>
          <br>
          <br>
          Werner Wielandner<br>
          Tel.: 0650 980 50 70<br>
          <br>
          Thomas Wielandner<br>
          Tel.: 0677 617 27 444<br>
          <br>
          E-Mail: <a href="mailto:info@oishausgmocht.at">info@oishausgmocht.at</a>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<style scoped>
.text {
	width: 100%;
	height: 600px;
	display: inline-block;
	overflow: hidden;
	border:1px solid #FFCC00;
}

.text_inner {
	max-width:500px;
	margin:0 auto;
	text-align:center;
	position: absolute;
	left: 50%;
	margin-left: -190px;
	top: 50%;
	margin-top: -180px;
}

.logo {
	display:inline-block;
	margin-bottom:20px;
	width: 380px;
	height: 59px;
	background-size:100% auto;
	background-image:url(@/assets/oishausgmocht.png);
}
</style>
