<script setup>
import Event from '@/components/Event.vue'
import { ref, onMounted } from 'vue'

const events = ref([])
const error = ref(null)
const headers = {'Content-Type': 'application/json'}

function checkStatus(resp) {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }
  return parseJSON(resp).then((resp) => {
    throw resp;
  })
}

function parseJSON(resp) {
  return (resp.json ? resp.json() : resp)
}

onMounted(async () => {
  console.info("strapiUrl: " + strapiUrl);
  try {
    const response = await fetch(strapiUrl + "api/events?populate=image", {
      method: 'GET',
      headers: headers,
    }).then(checkStatus)
      .then(parseJSON)
    events.value = response.data
  } catch (error) {
    error.value = error
  }
})
</script>

<template>
  <v-card class="mx-auto" max-width="600" border>
    <v-container fluid>
      <Event
        v-for="event in events"
        :name="event.attributes.name"
        :description="event.attributes.description"
        :img-url="event.attributes.image.data.attributes.url"
        :key="event.id"
      />
    </v-container>
  </v-card>
</template>
