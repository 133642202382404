<script setup>
import { reactive } from 'vue'
import { RouterView } from 'vue-router'
import { mdiViewSequential } from '@mdi/js'

import AIcon from '@/assets/a.svg'
import BeeIcon from '@/assets/oishausgmocht_bee-icon.svg'
import WIcon from '@/assets/w.svg'
import appBarTitleImageUrl from '@/assets/oishausgmocht.png'

const footer = reactive({
  buttons: [
    {
      text: "Kontakt",
      link: "/contacts"
    },
    {
      text: "Impressum",
      link: "/imprint"
    }
  ]
})
</script>

<template>
  <v-app>

    <v-app-bar color="surface" density="prominent">

      <template #prepend>
        <v-app-bar-nav-icon color="surface-bright" size="x-large" :icon="mdiViewSequential">
          <!-- The v-app-bar-nav-icon somehow doesnt work. -->
          <v-icon size="x-large" :icon="mdiViewSequential"/>

          <v-menu activator="parent">
            <v-list>
              <v-list-item to="/categories">
                <v-list-item-title>Wos gibts?</v-list-item-title>
              </v-list-item>
              <v-list-item to="/products">
                <v-list-item-title>Produkte</v-list-item-title>
              </v-list-item>
              <v-list-item to="/events">
                <v-list-item-title>Wo seng ma ins?</v-list-item-title>
              </v-list-item>
              <v-list-item to="/contacts">
                <v-list-item-title>Des sand mia!</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar-nav-icon>
      </template>

      <template #image>
        <div class="app-bar-image">
          <WIcon class="app-bar-image-parts"/>
          <BeeIcon class="app-bar-image-parts"/>
          <AIcon class="app-bar-image-parts"/>
        </div>
      </template>

    </v-app-bar>

    <v-main>
      <RouterView />
    </v-main>

    <v-footer>
      <v-row justify="center" no-gutters>
        <v-btn
          v-for="button in footer.buttons"
          :key="button.text"
          class="mx-2"
          color="white"
          rounded="xl"
          variant="text"
          :href="button.link"
        >
          {{ button.text }}
        </v-btn>
        <v-col class="text-center mt-4" cols="12">
          {{ new Date().getFullYear() }} — <strong>Hausgmocht</strong>
        </v-col>
      </v-row>
    </v-footer>

  </v-app>
</template>

<style scoped>
.app-bar-image {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.app-bar-image-parts {
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
