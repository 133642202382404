<script setup>
import Product from '@/components/Product.vue'
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const title = ref("Produkte")
const products = ref([])
const error = ref(null)
const headers = {'Content-Type': 'application/json'}

function checkStatus(resp) {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }
  return parseJSON(resp).then((resp) => {
    throw resp;
  })
}

function parseJSON(resp) {
  return (resp.json ? resp.json() : resp)
}

onMounted(async () => {
  console.info("strapiUrl: " + strapiUrl);
  var productsUrl = strapiUrl + "api/products?pagination[pageSize]=100&populate[0]=image&sort[0]=category.name&sort[1]=name"

  if (route.query.category) productsUrl = productsUrl + "&populate[1]=category&filters[category][$eq]=" + route.query.category

  try {
    const response = await fetch(productsUrl, {
      method: 'GET',
      headers: headers,
    }).then(checkStatus)
      .then(parseJSON)
    products.value = response.data
    if (route.query.category) title.value = products.value[0].attributes.category.data.attributes.name
  } catch (error) {
    error.value = error
  }
})
</script>

<template>
  <v-container fluid>
    <p class="text-h3 text-center text-primary mb-3">
      {{ title }}
    </p>
    <v-card class="mx-auto d-flex justify-center flex-wrap" max-width="900" border>
      <Product
        v-for="product in products"
        :name="product.attributes.name"
        :description="product.attributes.description"
        :price="product.attributes.price"
        :img-url="product.attributes.image.data.attributes.formats.small.url"
        :key="product.id"
      />
    </v-card>
  </v-container>
</template>
