<script setup>
import { computed } from 'vue'
import { StrapiBlocks } from 'vue-strapi-blocks-renderer'

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  description: {
    type: String,
    required: true
  },
  imgUrl: {
    type: String,
    required: true
  },
  time: {
    type: String,
    required: true
  }
})

const imgSrc = computed(() => {
  return strapiUrl.replace(/\/$/, '') + props.imgUrl
})
</script>

<template>
  <v-card
    class="mb-2"
    density="compact"
    :title="name"
    variant="text"
  >
    <v-img height="128" :src="imgSrc" cover></v-img>

    <v-card-text class="pa-10">
      <StrapiBlocks :content="description"/>
    </v-card-text>
  </v-card>
</template>
