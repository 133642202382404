<script setup>
import { computed } from 'vue'
import { StrapiBlocks } from 'vue-strapi-blocks-renderer'
import { mdiCurrencyEur } from '@mdi/js'

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  description: {
    type: String,
    required: true
  },
  price: {
    type: String,
    required: false
  },
  imgUrl: {
    type: String,
    required: true
  }
})

const imgSrc = computed(() => {
  return strapiUrl.replace(/\/$/, '') + props.imgUrl
})
</script>

<template>
  <v-card
    class="ma-2"
    max-width="400"
    density="compact"
    prepend-icon="@/assets/oishausgmocht_bee-icon.svg"
    :title="name"
    variant="text"
  >
    <v-img height="300" :src="imgSrc" cover>
      <template v-slot:placeholder>
        <div class="d-flex align-center justify-center fill-height">
          <v-progress-circular
            color="grey-lighten-4"
            indeterminate
          />
        </div>
      </template>
    </v-img>

    <v-card-text class="pa-10">
      <StrapiBlocks :content="description"/>
    </v-card-text>

    <v-card-actions>
      <v-list-item class="w-100">
        <template v-slot:append>
          <p class="text-h4 text-left text-primary">
            {{ price }}
          </p>
          <v-icon class="me-1" :icon="mdiCurrencyEur"/>
        </template>
      </v-list-item>
    </v-card-actions>
  </v-card>
</template>
