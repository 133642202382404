<script setup>
import { ref, onMounted } from 'vue'
import { StrapiBlocks } from 'vue-strapi-blocks-renderer'

const error = ref(null)
const headers = {'Content-Type': 'application/json'}

const props = defineProps({
  slot: {
    type: String,
    required: true
  }
})

// StrapiBlocks fails without initial content.
const content = ref([
  {
    type: 'paragraph',
    children: [{ type: 'text', text: '' }],
  },
])

function checkStatus(resp) {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }
  return parseJSON(resp).then((resp) => {
    throw resp;
  })
}

function parseJSON(resp) {
  return (resp.json ? resp.json() : resp)
}

onMounted(async () => {
  console.info("strapiUrl: " + strapiUrl);
  try {
    const response = await fetch(strapiUrl + "api/texts?filters[slot][$eq]=" + props.slot, {
      method: 'GET',
      headers: headers,
    }).then(checkStatus)
      .then(parseJSON)
    console.info(response.data)
    content.value = response.data[0].attributes.content
  } catch (error) {
    error.value = error
  }
})
</script>

<template>
  <StrapiBlocks :content="content"/>
</template>
